* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}

button {
	display: block;
	text-decoration: none;
	cursor: pointer;
	border: none;
}
